import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

const whyCiderList = [
	{
		id: '1',
		label: 'Remote-First Working Environment',
		title: 'Remote-First Working Environment',
		text: "Experience the efficiency and flexibility of our remote-first work culture. From streamlined remote on-boarding to performance evaluation, teamwork and collaboration, every process is designed to enhance your work-from-anywhere experience. </br></br> At Cider, the world is your office. We work from 2 continents and 12 countries: USA, Canada, Poland, Portugal, Israel, Ukraine, Montenegro, Spain, France, Turkey, Egypt, Azerbaijan."
	},
	{
		id: '2',
		label: 'Learn & Grow Opportunities',
		title: 'Learn & Grow Opportunities',
		text: `Nurture your professional development with ample learning opportunities. We encourage a culture of continuous growth, providing resources and support for your career advancement:
		<ul class="inner-list">
			<li>Personal development plan</li>
			<li>Ongoing feedback</li>
			<li>On-boarding program</li>
			<li>Management development program</li>
			<li>Technical learning programs</li>
			<li>Team collaboration workshops</li>
			<li>On the job learning assignments</li>
		</ul>`
	},
	{
		id: '3',
		label: 'Diverse career paths',
		title: 'Diverse career paths',
		text: `Your dedication and achievements don't go unnoticed at Cider. Explore diverse career paths and unlock promotion options as you contribute to our shared success:
		<ul class="inner-list">
			<li>360 Feedback for every Employee</li>
			<li>Professional skills assessment</li>
			<li>Technical and Managerial Career Path</li>
		</ul>`
	},
	{
		id: '4',
		label: 'Elevate Your Career with Innovation',
		title: 'Elevate Your Career with Innovation',
		text: `Join our pioneering tech team, where we exclusively partner with senior professionals. Experience the thrill of working on cutting-edge innovations in customized software solutions, gaining invaluable expertise and propelling your career to new heights: </br></br><b>Cider Hacktivate 2023 – Cider’s AI Hackathon</b></br></br>Employee participation rate - 98%<br>Post hackathon implementation: 3 projects defined
		`
	}
]

const WhyCider = () => {
	const [activeTab, setActiveTab] = useState(0)

	return (
		<div className="why-cider">
			<div className="container">
				<Fade bottom duration={1000}>
					<span className="caption why-cider__caption">Why Cider?</span>
				</Fade>
				<div className="why-cider__wrap">
					<ul className="why-cider__nav">
						{whyCiderList.map((item, index) => {
							return (
								<Fade bottom duration={1000} key={item.id}>
									<li className={`why-cider__item ${activeTab === index ? 'active' : ''}`}>
										<span onMouseEnter={() => setActiveTab(index)}>{item.label}</span>
									</li>
								</Fade>
							)
						})}
					</ul>
					<div className="why-cider__content">
						<Fade bottom duration={1000}>
							<span className="title why-cider__title">
								{whyCiderList[activeTab].title}
							</span>
						</Fade>
						<Fade bottom duration={1000}>
							<div className="why-cider__text">
								<p dangerouslySetInnerHTML={{ __html: whyCiderList[activeTab].text }}></p>
							</div>
						</Fade>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WhyCider
