import React from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

const HaveQuestions = () => {
	return (
		<div className="have-questions">
			<div className="container">
				<Fade duration={1000} bottom>
					<span className="title have-questions__title">Have questions?</span>
				</Fade>
				<Fade duration={1000} bottom>
					<div className="have-questions__btns">
						<a className="btn btn--black" href="mailto:jobs@cider.consulting">
							jobs@cider.consulting
         				</a>
						<a
							href="https://www.linkedin.com/company/ciderconsulting"
							target="_blank"
							rel="noopener noreferrer"
							className="btn"
						>
							Contact us on Linkedin
						</a>
					</div>
				</Fade>
			</div>
		</div>
	)
}

export default HaveQuestions
