import React, { useState, useEffect, useRef } from "react"
import Fade from "react-reveal/Fade"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WhoWeAre from "../components/WhoWeAre"
import HaveQuestions from "../components/HaveQuestions"
import WhyCider from "../components/WhyCider"
import Openings from "../components/Openings"
import Loader from "../components/Loader"

import Heading from "../components/Heading"
import "../styles/pages-style/careers.scss"
import img from "../images/new-careers.svg"

const Careers = () => {
	const [animate, setAnimate] = useState(true)

	function changeLoad() {
		setTimeout(() => {
			setAnimate(false)
		}, 500)
	}

	useEffect(() => {
		window.addEventListener('load', changeLoad(), false)
	}, [])

	const whoWeAreBlock = useRef(null)
	const [offsetWhoWeAre, setOffsetWhoWeAre] = useState(0)
	const [bgChangeWhoWeAre, setBgChangeWhoWeAre] = useState(false)

	const careersDescrBlock = useRef(null)
	const [offsetCareersDescr, setOffsetCareersDescr] = useState(0)
	const [bgChangeCareersDescr, setBgChangeCareersDescr] = useState(false)

	const whyCiderBlock = useRef(null)
	const [offsetWhyCider, setOffsetWhyCider] = useState(0)
	const [bgChangeWhyCider, setBgChangeWhyCider] = useState(false)

	const handleScroll = (e) => {
		let offsetTop = window.pageYOffset
		let windowHeight = window.innerHeight

		offsetTop + (windowHeight / 2) > offsetWhoWeAre ? setBgChangeWhoWeAre(true) : setBgChangeWhoWeAre(false)
		offsetTop + (windowHeight / 2) > offsetCareersDescr ? setBgChangeCareersDescr(true) : setBgChangeCareersDescr(false)
		offsetTop + (windowHeight / 2) > offsetWhyCider ? setBgChangeWhyCider(true) : setBgChangeWhyCider(false)
	}

	useEffect(() => {
		let bodyRect = document.body.getBoundingClientRect()

		let whoWeAreRect = whoWeAreBlock.current.getBoundingClientRect()
		let whoWeArePos = whoWeAreRect.top - bodyRect.top
		setOffsetWhoWeAre(whoWeArePos)

		let careersDescrRect = careersDescrBlock.current.getBoundingClientRect()
		let careersDescrPos = careersDescrRect.top - bodyRect.top
		setOffsetCareersDescr(careersDescrPos)

		let whyCiderRect = whyCiderBlock.current.getBoundingClientRect()
		let whyCiderPos = whyCiderRect.top - bodyRect.top
		setOffsetWhyCider(whyCiderPos)

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
		
	}, [offsetWhoWeAre, offsetCareersDescr, offsetWhyCider, handleScroll])

	return (
		<div>
			{!animate ? null : <Loader />}
			<Layout>
				<SEO title="Services | Cider Consulting" />
				<Heading
					caption="Careers"
					text="We're Hiring! Join us and do your life's best work at Cider"
					bottomContent={
						<div className="bottom-careers">
							<Fade bottom duration={1000}>
								<p className="bottom-careers__text">We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development</p>
							</Fade>
							<Fade bottom duration={1000}>
								<img src={img} alt="" />
							</Fade>
						</div>
					}
				/>
				<div ref={whoWeAreBlock} className={bgChangeWhoWeAre ? 'change-bg-who-we-are' : ''}>
					<WhoWeAre />
				</div>
				<div ref={careersDescrBlock} className={bgChangeCareersDescr ? 'change-bg-careers-descr' : ''}>
					<div className="careers-descr">
						<div className="container">
							<div className="title careers-descr__text">
								<Fade bottom duration={1000}>
									<p>We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development</p>
								</Fade>
							</div>
						</div>
					</div>
				</div>
				<div ref={whyCiderBlock} className={bgChangeWhyCider ? 'change-bg-why-cider' : ''}>
					<WhyCider />
					<div className="career-contact">
						Choose a career with Cider for more than just a job—choose a pathway to personal and professional success. 
						<br></br>
						<br></br>
						Email <a href="mailto:jobs@cardinal.cider.consulting">jobs@cardinal.cider.consulting</a> to explore exciting opportunities and be a part of our innovative and remote-first team!
					</div>
					{/* <Openings /> */}
				</div>
				<HaveQuestions />
			</Layout>
		</div>
	)
}

export default Careers

export const query = graphql`
  query CareersPageQuery {
    site {
      siteMetadata {
        description
      }
    }
    Erin: file(name: { eq: "Erin" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    team: file(name: { eq: "team" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careers2: file(name: { eq: "careers2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
