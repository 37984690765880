import React from "react"
import Fade from "react-reveal/Fade"
import img from "../../images/team.jpg"
import "./style.scss"

const WhoWeAre = () => {

	return (
		<div className="who-we-are">
			<div className="container">
				<Fade bottom duration={1000}>
					<span className="caption who-we-are__caption">Who we are</span>
				</Fade>
				<div className="who-we-are__wrap">
					<div className="who-we-are__text">
					<Fade bottom duration={1000}>
						<p>We’re a team of creative people working on stellar products.</p>
					</Fade>
					<Fade bottom duration={1000}>
						<p>We're headquartered a few miles outside New York City, and our team largely works remotely, spread across several cities around the world.</p>
					</Fade>
					<Fade bottom duration={1000}>
						<p>We are a team of creative people, developers, business analysts, designers, friends, hobbyists and tinkerers.</p>
					</Fade>
					</div>
					<Fade bottom duration={1000}>
						<div className="who-we-are__img">
							<img src={img} alt="" />
						</div>
					</Fade>
				</div>
			</div>
		</div>
	)
}

export default WhoWeAre
